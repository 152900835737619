import { useRef } from 'react';

import { css } from '@emotion/css';
import { useWindowSize } from '@uidotdev/usehooks';
import { formatISO9075 } from 'date-fns';
import { LayoutGroup, m, useMotionValue } from 'framer-motion';
import { observer } from 'mobx-react-lite';

import { ViewByState } from '^/userWeb/Components/ViewByState';
import { borders } from '^/userWeb/styles/commonClasses';

import { RelayControl } from './RelayControl';
import { SensorsDetailPopupDefaultStyles } from './SensorsDetail.popup.styles';
import { SensorsDetailPopupViewModel } from './SensorsDetail.popup.viewmodel';
import { TempHumidityChart } from './TempHumidityChart';

export const defaultStyles = {
  valueText: css`
    margin-right: 8px;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  `,
};
export const SensorsDetailPopup = observer(function SensorsDetailPopup(p: {
  viewmodel: SensorsDetailPopupViewModel,
  stylesOverride?: typeof SensorsDetailPopupDefaultStyles,
}) {
  const styles = p.stylesOverride || SensorsDetailPopupDefaultStyles;
  // 0: initial view
  // 1: 100% of: show more data-view
  const moreDataViewRatio = useMotionValue(0);
  // const a = useScroll((s) => {
  //   s.movement
  // }, {
  //   target:
  // })

  const winSize = useWindowSize();
  const winWidth = winSize.width || 0;
  const winHeight = winSize.height || 0;
  const moreDataViewRatioDragRef = useRef<HTMLDivElement>(null);
  const isWideView = winWidth > winHeight + 100;

  // const activeView = p.viewmodel.activeView.value;

  // eslint-disable-next-line no-nested-ternary
  return (
    <LayoutGroup id="SensorsDetailPopup__ROOT">
      <p
        id="SensorsDetailPopup__DeviceInfo"
        className={[
          styles.deviceInfoRoot,
          // borders.DEBUG_blueBorderDashed,
        ].join(' ')}
      >
        환경제어기: {p.viewmodel.device.name} <br />
        <span
          className={[
            styles.deviceInfoHostText,
          ].join(' ')}
        >
          {p.viewmodel.device.accessHost}:{p.viewmodel.device.accessPort}&#10;
          {/* version: {p.viewmodel.device.deviceVersion}&#10;
          phone: {p.viewmodel.device.phone} */}
        </span>
      </p>
      <div
        id="SensorsDetailPopup__INNERROOT"
        ref={moreDataViewRatioDragRef}
        className={[
          styles.innerRoot,
          // borders.DEBUG_blueBorderThickDashed,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
        style={isWideView ? {
          // flex-direction: row;
          flexDirection: 'row',
          // height:
          // width: '100%',
          // overflowX: 'scroll',
        } : {
          flexDirection: 'column',
        }}
      >
        <div
          id="SensorsDetailPopup__DEBUG_VALUES"
          className={[
            styles.cssDebugValues,
          ].join(' ')}
        >
          <h1>w: {winWidth} / h: {winHeight}</h1>
          {/* <h2>active: {activeView.type}</h2> */}
          {/* <h1>{moreDataViewRatioMobx.current()?.value.value}</h1> */}
        </div>

        <m.div
          layout
          id="RelayControl_Wrapper"
          className={[
            // borders.DEBUG_greenBorderDashed,
            css`
              width: 100%; height: 100%;
              overflow: auto;
            `,
          ].join(' ')}
          onMouseEnter={() => {
            p.viewmodel.activeView.set({
              type: 'RelayControl',
            });
          }}
          style={
            {
              flexBasis: isWideView ? 200 : winHeight * 0.2,
            }
          }
        >
          <RelayControl
            viewmodel={p.viewmodel}
          />
        </m.div>
        <ViewByState
          data={p.viewmodel.sensorData}
          errorView={<p> error </p>}
          loadingView={<p> loading </p>}
        >
          {(value) => {
            // eslint-disable-next-line no-nested-ternary

            return (
              <>
                <m.div
                  id="SensorsDetailsPopup__SensorGraph"
                  layout
                  layoutId="11a33"
                  onMouseDown={() => {
                    p.viewmodel.activeView.set({
                      type: 'SensorDataGraph',
                    });
                  }}
                  className={[
                    css`
                      width: 100%;
                      height: 100%;
                      overflow: hidden;
                    `,
                    // borders.DEBUG_greenBorderDashed,
                  ].join(' ')}
                  style={{
                    flexBasis: isWideView
                      ? winWidth * 0.5
                      : winHeight * 0.2,
                  }}
                >

                  <TempHumidityChart
                    viewmodel={p.viewmodel}
                    data={value}
                    scrollToTimeCallback={(t) => {

                    }}
                  // dimension={{
                  //   // width: graphWidth,
                  //   // height: graphHeight,
                  // }}
                  />
                </m.div>
                <m.div
                  onMouseDown={() => {
                    p.viewmodel.activeView.set({
                      type: 'SensorDataTable',
                    });
                  }}
                  layout
                  layoutId="SensorDataTable"
                  className={[
                    css`
                      display: grid;
                      grid-template-columns: 1fr;
                      grid-template-rows: 1fr;
                      /* overflow-y: scroll; */
                      /* height: calc(100% - 220px); */
                      border-radius: 16px;
                      /* position: absolute;
                      bottom: 10px;
                      left: 10px;
                      right: 10px; */
                      height: 100%;
                      width: 100%;
                    `,
                    // borders.DEBUG_blueBorderDashed,
                  ].join(' ')}
                  role="list"
                  style={{
                    flexBasis: isWideView
                      ? Math.max(winWidth * 0.2, 200)
                      : 400,
                  }}
                >

                  <m.div
                    layout="position"
                    className={[
                      css`
                        /* height: 300px; */
                        /* height: calc(100% - 900px); */
                        height: 100%;
                        /* background-color: green; */
                        overflow-y: scroll;
                        flex: 1;
                        display: grid;
                        /* width: 100px; */
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr;
                        overflow-y: scroll;
                      `,
                      // borders.DEBUG_greenBorderDashed,
                      // borders.DEBUG_blueBorderThickDashed,
                    ].join(' ')}
                  >
                    {value.map((v) => {
                      return (
                        <div
                          key={v.id}
                          className={css`
                            border-bottom: 1px dotted black;
                            margin-left: 8px;
                            margin-right: 8px;
                            margin-top: auto;
                            padding-top: 8px;
                            padding-bottom: 8px;
                          `}
                        >
                          <p
                            className={defaultStyles.valueText}
                          >
                            {formatISO9075(v.assumedTime)}
                          </p>
                          <p
                            className={[
                              css`
                                display: inline-block;
                                margin: 0;
                              `,
                            ].join(' ')}
                          >
                            온도:
                            <span
                              className={defaultStyles.valueText}
                            >
                              {v.temperature.toFixed(1)}°C
                            </span>
                            습도:
                            <span
                              className={defaultStyles.valueText}
                            >
                              {v.humidity.toFixed(1)}%
                            </span>
                            암모니아:
                            <span
                              className={defaultStyles.valueText}
                            >
                              {v.ammoniaGas === -1
                                ? ' - '
                                : `${v.ammoniaGas.toFixed(1)}%`}
                            </span>
                          </p>
                        </div>
                      );
                    })}
                  </m.div>
                </m.div>
              </>
            );
          }}
        </ViewByState>

      </div>
    </LayoutGroup>
  );
});

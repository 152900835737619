import { css, cx } from '@emotion/css';

export const SensorsDetailPopupDefaultStyles = {
  deviceInfoRoot: css`
    width: fit-content;
    font-weight: bold;
    position: absolute;
    top: -36px;
    left: 12px;
    line-height: 0.65;
  `,
  deviceInfoHostText: css`
    font-size: 10px;
    white-space: pre-line;
    color: blue;
  `,

  innerRoot: css`
    /* background-color: green; */
    display: flex;

    padding-left: 16px;
    padding-right: 16px;
    gap: 8px;
    width: 100%;
    height: 100%;
    position: relative;
    > * {
      flex-shrink: 0;
    }
  `,
  cssDebugValues: css`
    position: absolute;
    right: 10px;
    top: -50px;
    pointer-events: none;
    display: none;
  `,
} as const;

/// <reference lib="dom" />
import { domMax, LazyMotion } from 'framer-motion';
import { createRoot } from 'react-dom/client';
import 'normalize.css';
import './index.css';
import '@fontsource/noto-sans-kr/korean-100.css';
import '@fontsource/noto-sans-kr/korean-300.css';
import '@fontsource/noto-sans-kr/korean-400.css';
import '@fontsource/noto-sans-kr/korean-500.css';
import '@fontsource/noto-sans-kr/korean-700.css';
import '@fontsource/noto-sans-kr/korean-900.css';
import { Toaster } from 'react-hot-toast';

// import { lockScreenOrientation } from './lockScreenOrientation';
import { createAPIClient } from './apiClient';
import { promptInstall } from './promptInstall';
import { registeredServiceWorker } from './registeredServiceWorker';
import { router } from './routes';
import { RoutesPageRenderer } from './routesPageRenderer';
import { getDefaultAppContext } from './types/AppContext';
import { runCheckVersionAndRefreshInterval } from './utils/checkVersionAndRefresh';

runCheckVersionAndRefreshInterval();

const apiClient = createAPIClient({
  serverOrigin: window.location.origin,
  isHTTPS: window.location.protocol === 'https:',
  host: window.location.host,
  // serverOrigin: 'https://web.ssnetworks.kr',
  basePath: '/userapi_v2',
});

const appContext = getDefaultAppContext(
  apiClient,
);

const { RouteProvider } = router;
const rootElem = document.getElementById('APP_ROOT');
if (!rootElem) {
  throw new Error('ROOT ELEM NOT DEFINED!');
}
const root = createRoot(rootElem);
function addInstallPromptByClick() {
  registeredServiceWorker
    .then((sw) => {
      promptInstall();
    });
  rootElem?.removeEventListener('click', addInstallPromptByClick);
}
rootElem.addEventListener('click', addInstallPromptByClick);
root.render(
  <LazyMotion features={domMax}>

    <RouteProvider>
      <RoutesPageRenderer appContext={appContext} />
      <Toaster />
    </RouteProvider>
  </LazyMotion>,
);

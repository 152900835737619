import {
  createTRPCProxyClient,
} from '@trpc/client';
import { httpBatchLink } from '@trpc/client/links/httpBatchLink';
import { loggerLink } from '@trpc/client/links/loggerLink';
import { splitLink } from '@trpc/client/links/splitLink';
import { wsLink, createWSClient } from '@trpc/client/links/wsLink';
import superjson from 'superjson';
import { devtoolsLink } from 'trpc-client-devtools-link';

import type { UsersAPIEndpoint } from '^/server/trpcAppRouter';

import { catchAndReturnException } from './catchAndReturnException';
import { RouterWithErrorReturn, TRPCErrorPromise } from './types';

console.error('fetch', fetch);

export type CommonErrorType = TRPCErrorPromise<UsersAPIEndpoint>;

export function createAPIClient(opts: {
  serverOrigin: string,
  isHTTPS: boolean,
  host: string,
  basePath: string,
}) {
  const basePath = opts.basePath.startsWith('/')
    ? opts.basePath : `/${opts.basePath}`;
  const baseUrl = `${opts.serverOrigin}${basePath}`;
  const wsClient = createWSClient({
    url: `${opts.isHTTPS ? 'wss' : 'ws'}://${opts.host}/subscription`,
  });

  const client = createTRPCProxyClient<
    RouterWithErrorReturn<UsersAPIEndpoint>
  // UsersAPIEndpoint
  // UsersAPIEndpoint
  >({
    transformer: superjson,
    links: [
      devtoolsLink(),
      //
      // catchAndReturnException<UsersAPIEndpoint>(),
      loggerLink(),
      // httpBatchLink({
      //   maxBatchSize: 1,
      //   url: baseUrl,
      // }),
      splitLink({
        condition(op) {
          return op.type === 'subscription';
        },
        true: wsLink({
          client: wsClient,
        }),
        false: httpBatchLink({
          url: baseUrl,
        }),
      }),
    ],
  });
  return client;
}

export type APIClient = ReturnType<typeof createAPIClient>;

import { makeAutoObservable } from 'mobx';

import {
  asSuccessPromise,
  getCurrentFreshness,
  mergeSuccessResult,
} from '^/types/__ResultType';
import { AppContext } from '^/userWeb/types/AppContext';

export class RecentCowTailUpSlidesViewModel {
  constructor(
    public appContext: AppContext,
    public freshness = getCurrentFreshness(),
  ) {
    makeAutoObservable(this);
  }

  get userToken() {
    return this.appContext.tokenRepo.userToken;
  }

  get myInfo() {
    if (this.userToken.status !== 'success') {
      return this.userToken;
    }
    const myInfoResult = this.appContext
      .myInfoCore
      .myInfo(this.userToken.value, this.freshness);

    return mergeSuccessResult(
      myInfoResult, this.userToken.value,
    );
  }

  get list() {
    if (this.myInfo.status !== 'success') {
      return this.myInfo;
    }
    const result = this.appContext
      .cowMountListCore.getList({
        // TODO: remove...?
        __CowMountHistoryListFilterType: null,
        userId: this.myInfo.value[0].id,
        userToken: this.myInfo.value[1],
      }, this.freshness);
    if (result.status !== 'success') {
      return result;
    }
    return asSuccessPromise(result.value
      .filter((v) => v.status === 'success'
        && v.value.detectionType === 'cowtailup',
      ));
  }
}

import { z } from 'zod';

export const DeviceRelayStatusTypes = z.nativeEnum({
  open: '1',
  stop: '2', // hide stop button:
  closed: '3',
} as const);
export type DeviceRelayStatusTypesT = z.infer<typeof DeviceRelayStatusTypes>;

export const DeviceRelayNameConst = {
  // // curtains
  // 커튼1: 'A',
  // 커튼2: 'B',
  // 커튼3: 'C',
  // 커튼4: 'D',
  // 커튼5: 'E',
  // 커튼6: 'F',
  // 커튼7: 'G',
  // 커튼8: 'H',

  전등1: 'A',
  전등2: 'B',
  전등3: 'C',
  전등4: 'D',
  전등5: 'E',
  전등6: 'F',
  선풍기1: 'G',
  선풍기2: 'H',


  // ceiling
  좌측천장1: 'I',
  우측천장1: 'J',

  좌측천장2: 'K',
  우측천장2: 'L',

  천장: 'M', //

  // 선풍기1: 'N',
  // 선풍기2: 'O',

  // 전등1: 'P',
  // 전등2: 'Q',
} as const;
export const DeviceRelayNames = z.nativeEnum(
  DeviceRelayNameConst,
);
// .brand('DeviceNamesV1');

type b = keyof typeof DeviceRelayNames.enum;

export const DeviceRelayNamesKeys = Object
  .keys(DeviceRelayNames.enum) as (keyof typeof DeviceRelayNames.enum)[];
export type DeviceRelayNamesT = z.infer<typeof DeviceRelayNames>;

import React, { useEffect, useLayoutEffect, useRef } from 'react';

import { css } from '@emotion/css';
import { m } from 'framer-motion';
import {
  createChart, ColorType, UTCTimestamp, IChartApi,
  AutoscaleInfo,
  BarPrice,
  Time,
  TickMarkType,
} from 'lightweight-charts';
import { observer } from 'mobx-react-lite';

import { IotDevicePhysicalEventIdT } from '^/__generated__/pothosZod/generated_scalars';
import { ExtractPromiseSuccess } from '^/types/__ResultType';
import { useLifecycles } from '^/userWeb/hooks/useLifecycle';

import { SensorsDetailPopupViewModel } from './SensorsDetail.popup.viewmodel';

export const TempHumidityChart = observer(function TempHumidityChart(
  props: {
    viewmodel: SensorsDetailPopupViewModel,
    data: ExtractPromiseSuccess<
      SensorsDetailPopupViewModel['sensorData']
    >;
    colors?: {
      backgroundColor?: string;
      lineColor?: string;
      textColor?: string;
      areaTopColor?: string;
      areaBottomColor?: string;
    };
    dimension?: {
      width: number;
      height: number;
    };
    scrollToTimeCallback?: (id: IotDevicePhysicalEventIdT) => void;
  }) {
  const {
    data,
    dimension,
    viewmodel,
    scrollToTimeCallback,
    colors: {
      backgroundColor = 'white',
      lineColor = '#2962FF',
      textColor = 'black',
      areaTopColor = '#2962FF',
      areaBottomColor = 'rgba(41, 98, 255, 0.28)',
    } = {
      // data: initialData,
    },
  } = props;

  const dataWithDateFixed = data
    .map((d) => {
      const utctime = d.assumedTime.getTime() / 1000 as UTCTimestamp;
      const time = utctime + (3600 * 9) as UTCTimestamp; // UTC to KST: +9
      return {
        ...d,
        time,
      };
    })
    .reverse();

  const chartContainerRef = useRef<
    HTMLDivElement
  >(null);
  const chart = useRef<
    ReturnType<typeof createChart> | null
  >(null);
  // let chart: ReturnType<typeof createChart>
  const handleResize = () => {
    chart.current?.applyOptions({
      width: chartContainerRef.current?.clientWidth,
    });
  };
  const temperatureSeriesRef = useRef<
    ReturnType<IChartApi['addLineSeries']> | null
  >(null);

  const humiditySeriesRef = useRef<
    ReturnType<IChartApi['addLineSeries']> | null
  >(null);

  const ammoniaSeriesRef = useRef<
    ReturnType<IChartApi['addLineSeries']> | null
  >(null);

  useLifecycles(
    () => {
      if (!chartContainerRef.current) {
        return;
      }
      const chartContainer = chartContainerRef.current;
      const chartCurrent = createChart(chartContainerRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: backgroundColor },
          textColor,
        },
        autoSize: true,
        width: chartContainer.clientWidth,
        height: chartContainer.clientHeight,
        handleScale: {
          axisPressedMouseMove: false,
        },
        handleScroll: {
          vertTouchDrag: false,
        },
        rightPriceScale: {
          visible: true,
          ticksVisible: true,
        },
        leftPriceScale: {
          visible: true,
        },
        localization: {
          // timeFormatter(t: HorzScaleItem) {

          // }
          dateFormat: 'yyyy.MM.dd',
        },

        timeScale: {
          // tickMarkFormatter(
          //   time: Time,
          //   tickMarkType: TickMarkType,
          //   locale: string,
          // ) {
          //   return '123';
          // },

          ticksVisible: true,
          timeVisible: true,
        },

      });
      chart.current = chartCurrent;

      chart.current.timeScale().fitContent();

      const temperatureSeries = chart
        .current
        .addLineSeries({
          // lineColor,
          color: 'orange',
          lineWidth: 2,
          // title: '온도',
          priceFormat: {
            formatter: (v: BarPrice) => {
              return `${v}°C`;
            },
          },

          // pointMarkersRadius: 2,
          autoscaleInfoProvider: () => {
            return {
              priceRange: {
                maxValue: 30,
                minValue: 0,
              },
            } as AutoscaleInfo;
          },
          // topColor: areaTopColor,
          // bottomColor: areaBottomColor,
        });
      temperatureSeriesRef.current = temperatureSeries;
      temperatureSeries.setData(dataWithDateFixed
        .map((d) => {
          return {
            time: d.time,
            value: d.temperature,
          };
        }).toArray(),
      );

      const humiditySeries = chart
        .current
        .addLineSeries({
          // lineColor,
          color: 'blue',
          lineWidth: 2,
          title: '습도',
          priceFormat: {
            formatter: (v: BarPrice) => {
              return `${v}%`;
            },
          },

          // pointMarkersRadius: 2,
          autoscaleInfoProvider: () => {
            return {
              priceRange: {
                maxValue: 70,
                minValue: 10,
              },
            } as AutoscaleInfo;
          },
        });
      humiditySeriesRef.current = humiditySeries;
      humiditySeries.setData(dataWithDateFixed.map((d) => {
        return {
          time: d.time,
          value: d.humidity,
        };
      }).toArray());

      const ammoniaSeries = chart
        .current
        .addLineSeries({
          // lineColor,
          color: 'purple',
          lineWidth: 2,
          title: '암모니아',
          priceFormat: {
            formatter: (v: BarPrice) => {
              return `${v}ppm`;
            },
          },

          // pointMarkersRadius: 2,
          autoscaleInfoProvider: () => {
            return {
              priceRange: {
                maxValue: 70,
                minValue: 10,
              },
            } as AutoscaleInfo;
          },
        });
      ammoniaSeriesRef.current = ammoniaSeries;
      ammoniaSeries.setData(dataWithDateFixed
        .map((d) => {
          return {
            time: d.time,
            value: d.ammoniaGas,
          };
        })
        .filter((v) => {
          return v.value > -1;
        })
        .toArray());

      chartCurrent.subscribeDblClick(
        (e) => {
          chartCurrent.setCrosshairPosition(
            0,
            e.time || (Date.now() / 1000) as UTCTimestamp,
            temperatureSeries,
          );
          chartCurrent.setCrosshairPosition(
            0,
            e.time || (Date.now() / 1000) as UTCTimestamp,
            humiditySeries,
          );
          chartCurrent.setCrosshairPosition(
            0,
            e.time || (Date.now() / 1000) as UTCTimestamp,
            ammoniaSeries,
          );
        },
      );
      chartCurrent.subscribeCrosshairMove((e) => {
        // TODO: table scroll-to
      });

      window.addEventListener('resize', handleResize);
    },
    () => {
      window.removeEventListener(
        'resize', handleResize,
      );
      chart.current?.remove();
    },
  );
  // useLayoutEffect(() => {
  //   if (chart.current && dimension) {
  //     chart.current.applyOptions({
  //       width: dimension.width,
  //       height: dimension.height,
  //     });
  //     chart.current.autoSizeActive
  //   }
  // }, [dimension]);

  useEffect(() => {
    humiditySeriesRef.current?.setData(dataWithDateFixed.map((d) => {
      return {
        time: d.time,
        value: d.humidity,
      };
    }).toArray());
    temperatureSeriesRef.current?.setData(dataWithDateFixed
      .map((d) => {
        return {
          time: d.time,
          value: d.temperature,
        };
      }).toArray(),
    );
    ammoniaSeriesRef.current?.setData(dataWithDateFixed
      .map((d) => {
        return {
          time: d.time,
          value: d.ammoniaGas,
        };
      })
      .filter((v) => {
        return v.value > -1;
      })
      .toArray(),
    );
  }, [
    dataWithDateFixed,
  ]);

  return (
    <m.div
      layout="position"
      className={[
        css`
          width: 100%;
          height: 100%;
        `,
        // borders.DEBUG_blueBorderDashed,
      ].join(' ')}
      ref={chartContainerRef}
    />
  );
});

import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { VideoSectionHeader } from '^/userWeb/Components/VideoSectionHeader';
import { ViewByState } from '^/userWeb/Components/ViewByState';
import { routes } from '^/userWeb/routes';
import { hideScrollBar } from '^/userWeb/styles/commonClasses';
import { BackgroundColors } from '^/userWeb/styles/styleConstants';

import { DetectedVisitorsSlides } from './DetectedVisitorsSlides/DetectedVisitorsSlides';
import { LiveCamsSliderArea } from './LiveCamsSliderArea/LiveCamsSliderArea';
import { MainPageViewModel } from './MainPage.viewmodel';
import { RecentCowMountSlides } from './RecentCowMountSlides/RecentCowMountSlides';
import { TopWelcomeView } from './TopWelcomeView';
import { RecentCowTailUpSlides } from './RecentCowTailUpSlides/RecentCowTailUpSlides';

const videoContainerClassName = css`
  border-radius: 10px;
  /* height: 100%; */
  height: calc((100vw - 20px) * 9 / 16);
  background-color: ${BackgroundColors.gray_light};
  border-radius: 10px;
  overflow: hidden;
`;
export const MainPage: React.FC<{
  viewmodel: MainPageViewModel;
}> = observer(function MainPage({ viewmodel }) {
  return (
    <div
      id="main-page-root"
      className={cx(
        css`
                  height: 100%;
                  width: 100%;
                  overflow-y: scroll;
                `,
        hideScrollBar,
      )}
    >
      <ViewByState
        data={viewmodel.myInfo}
        loadingView={<></>}
        errorView={(e) => (
          <h2> {JSON.stringify(e)}</h2>
        )}
      >
        {([user]) => {
          console.log('JSON.stringify(user)', JSON.stringify(user));
          return (
            <TopWelcomeView
              userName={user.name}
              numNotifications={0}
            />
          );
        }}
      </ViewByState>
      <ViewByState
        data={viewmodel.liveCamsViewModel}
        loadingView={() => (
          <div
            className={videoContainerClassName}
          >
            loading...
          </div>
        )}
        errorView={(e) => (
          <div
            className={videoContainerClassName}
          >
            error: {JSON.stringify(e)}
          </div>
        )}
      >
        {(value) => (
          <LiveCamsSliderArea
            videoContainerClassName={videoContainerClassName}
            viewmodel={value}
          />
        )}
      </ViewByState>

      <VideoSectionHeader
        key="MountHistory"
        title="최근 발생한 승가"
        onClickShowAllButton={() => {
          routes.cowMountingHistory({
            detectionType: 'ride',
          })
            .push();
        }}
      />

      <RecentCowMountSlides
        detectionType="ride"
        viewmodel={viewmodel.recentCowMountHistoryViewModel}
      />
      <VideoSectionHeader
        key="StanzoneHistory"
        title="분만 예정 행동"
        onClickShowAllButton={() => {
        }}
      />
      <RecentCowTailUpSlides
        detectionType="cowtailup"
        viewmodel={viewmodel.recentCowTailupHistoryViewModel}
      />

      <VideoSectionHeader
        key="StanzoneHistory"
        title="최근 발생한 스탄존"
        onClickShowAllButton={() => {
          routes.cowMountingHistory({
            detectionType: 'stanzone',
          })
            .push();
        }}
      />
      <RecentCowMountSlides
        detectionType="stanzone"
        viewmodel={viewmodel.recentCowMountHistoryViewModel}
      />

      <VideoSectionHeader
        key="VisitorHistory"
        title="최근 방문자 기록"
        onClickShowAllButton={() => {
          routes.visitorHistory({})
            .push();
        }}
      />
      <DetectedVisitorsSlides
        viewmodel={viewmodel.detectedVisitorsViewModel}
      />
    </div>
  );
});

export default MainPage;

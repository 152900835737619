/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { css, cx } from '@emotion/css';
import type { DetectionType } from '@prisma/client';
import { formatISO9075 } from 'date-fns/formatISO9075';
import itiriri from 'itiriri';
import { observer } from 'mobx-react-lite';

import { SmallVideoThumbnailCell } from '^/userWeb/Components/SmallVideoThumbnailCell';
import { routes } from '^/userWeb/routes';
import { hideScrollBar } from '^/userWeb/styles/commonClasses';

import { RecentCowTailUpSlidesViewModel } from './RecentCowTailUpSlides.viewmodel';

export const RecentCowTailUpSlides: React.FC<{
  viewmodel: RecentCowTailUpSlidesViewModel,
  detectionType: DetectionType; // Add the detectionType prop
}> = observer(function RecentCowMountSlides({
  viewmodel,
  detectionType,
}) {
  if (viewmodel.list.status === 'loading') {
    return (
      <SmallVideoThumbnailCell
        date="로딩 중...!!!"
        key="loading"
      />
    );
  }
  if (viewmodel.list.status === 'failure') {
    return (
      <SmallVideoThumbnailCell
        date="에러"
        key="error"
      />
    );
  }

  const filteredResults = itiriri(viewmodel
    .list
    .value,
  )
    .filter((mountResult) => {
      if (mountResult.status === 'loading' || mountResult.status === 'failure') {
        return false;
      }
      return mountResult.value.detectionType === detectionType;
    })
    .take(5);
  return (
    <div
      id="RecentCowTailUpSlides"
      className={cx(
        hideScrollBar,
        css`
          display: flex;
          flex-direction: row;
          gap: 12px;
          padding-top: 8px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 8px;
          overflow-x: scroll;
        `,
      )}
    >
      {filteredResults.map((mountResult, nth) => {
        if (mountResult.status === 'loading') {
          return (
            <SmallVideoThumbnailCell
              date="로딩 중..."
              key={`loading_${nth}`}
            />
          );
        }
        if (mountResult.status === 'failure') {
          return (
            <SmallVideoThumbnailCell
              date="에러"
              key={`error_${nth}`}
              rootCSS={css`
                              border-color: red;
                            `}
            />
          );
        }
        const createdAt = new Date(mountResult.value.createdAt);
        return (
          <SmallVideoThumbnailCell
            text={`카메라 ${mountResult.value.cameraName}`}
            key={mountResult.value.id}
            videoSrc={mountResult.value.videoUrl}
            date={formatISO9075(createdAt)}
            rootCSS={css`
                          width: 200px;
                        `}
            onDoubleClick={() => {
              setTimeout(() => {
                routes.cowMountingHistory({
                  detectionType: 'cowtailup',
                  fullscreenNth: nth,
                  freshness: viewmodel.freshness,
                }).push();
              }, 100);
            }}
          />
        );
      })}
      <div
        onClick={() => routes
          .cowMountingHistory({ detectionType })
          .push()}
        className={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  white-space: nowrap;
                `}
      >
        ...더 보기
      </div>
    </div>
  );
});
